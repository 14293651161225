import { useMutation, useQuery } from '@apollo/client'
import { useEffect, useMemo, useState } from 'react'
import { useCookies } from 'react-cookie'
import GET_SETTINGS from '../gql/getSettings.gql'
import SUBSCRIBE_PUSH from '../gql/subscribePush.gql'
import UNSUBSCRIBE_PUSH from '../gql/unsubscribePush.gql'
import { useMe } from './useMe'

// Will be false in iOS Safari if they haven't added to home screen yet
export const isPushAvailable = 'PushManager' in window

// ! Note that there's a pushsubscriptionchange event, but currently
// no browsers fire it. But if they did, we'd have to handle updating
// it automatically if users already have it enabled.
// ? Also wondering if we have to re-register from within the service worker
// every time it activates or reinstalls or anything.

export function usePushNotifications() {
	const me = useMe()
	const [devicePushSub, setDevicePushSub] = useState(undefined)
	const [serviceWorkerReady, setServiceWorkerReady] = useState(false)
	const {
		data: {
			settings: { pushSubscriptionEndpoints: serverPushSubEndpoints } = {},
		} = {},
		loading: loadingServerPushSubEndpoints,
	} = useQuery(GET_SETTINGS, {
		skip: !me,
	})
	const [, setCookies] = useCookies(['disableAutoPushRequest'])
	const [subscribePush, { loading: subscribing }] = useMutation(SUBSCRIBE_PUSH)
	const [unsubscribePush, { loading: unsubscribing }] =
		useMutation(UNSUBSCRIBE_PUSH)

	useEffect(() => {
		navigator.serviceWorker?.ready
			.then((registration) => {
				// * `ready` will never resolve in a dev build
				setServiceWorkerReady(true)
				return registration.pushManager?.getSubscription()
			})
			.then((subscription) => setDevicePushSub(subscription || null))
	}, [])

	const isEnabled = useMemo(() => {
		if (!serverPushSubEndpoints || devicePushSub === undefined) {
			// We haven't loaded the info needed to determine if push is enabled yet
			return null
		} else {
			return serverPushSubEndpoints.includes(devicePushSub?.endpoint)
		}
	}, [serverPushSubEndpoints, devicePushSub?.endpoint])

	const setEnabled = (enabled) => {
		if (enabled) {
			window.Notification.requestPermission() // * pretty sure this auto-rejects in Brave incognito... Same for iOS Safari when testing locally or in simulator.
				.then((permission) => {
					if (permission === 'granted') {
						return navigator.serviceWorker.ready
					} else {
						throw new Error('Notification permission denied')
					}
				})
				.then(async (registration) => {
					const currSub = await registration.pushManager.getSubscription()
					return currSub
						? currSub
						: registration.pushManager.subscribe({
								userVisibleOnly: true,
								applicationServerKey: import.meta.env.VITE_VAPID_PUBLIC_KEY,
								// eslint-disable-next-line no-mixed-spaces-and-tabs
						  })
				})
				.then((subscription) => {
					setDevicePushSub(subscription)
					subscribePush({
						variables: {
							pushSubscriptionJson: JSON.stringify(subscription),
						},
						refetchQueries: ['GetSettings'],
					})
				})
				.catch((err) => console.error(err.message))
		} else {
			setCookies('disableAutoPushRequest', true, { path: '/', expires: false })
			if (devicePushSub) {
				devicePushSub.unsubscribe()
			}
			unsubscribePush({
				variables: {
					pushSubscriptionEndpoint: devicePushSub?.endpoint,
				},
				refetchQueries: ['GetSettings'],
			})
		}
	}

	const isLoading =
		loadingServerPushSubEndpoints || subscribing || unsubscribing

	return [
		isEnabled,
		isPushAvailable && serviceWorkerReady ? setEnabled : undefined,
		isLoading,
	]
}
