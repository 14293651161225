import {
	Divider,
	ListItem,
	ListItemButton,
	ListItemText,
	Stack,
	Typography,
} from '@mui/material'
import EventIcon from '@mui/icons-material/Event'
import { useNavigate } from 'react-router'
import { useMe } from '../hooks/useMe.js'
import { useMeWithConnections } from '../hooks/useMeWithConnections.js'
import { useParticipantships } from '../hooks/useParticipantships.js'
import { LazyUserGraph } from './LazyUserGraph.jsx'
import { ImDown } from './ImDown.jsx'
import dayjs from 'dayjs'

export const WannaItem = ({ wanna, onClick }) => {
	const me = useMe()
	// Need to retrieve our connections separately because we may not already be in the Wanna
	const meWithConnections = useMeWithConnections()
	const participantships = useParticipantships(wanna.id)
	const navigate = useNavigate()
	const latestMessage = wanna.messages[0]
	// TODO: use wanna.hasUnseenActivity??
	const seenLatestMsg =
		!latestMessage ||
		latestMessage?.sender?.id === me?.id ||
		latestMessage?.seenBy?.some((u) => u.id === me?.id)
	// dayjs constructor expects milliseconds; startTime is in seconds
	const startTime = dayjs(wanna.startTime * 1000)

	const handleClicked = () => {
		onClick ? onClick() : navigate(`/app/wannas/${wanna.id}`)
	}

	return (
		<Stack>
			<ListItem disablePadding>
				<ListItemButton
					onClick={handleClicked}
					sx={{ pb: 0 }}>
					<ListItemText
						primary={'wanna ' + wanna.activity + '?'}
						primaryTypographyProps={{
							fontWeight: !seenLatestMsg ? 'bold' : undefined,
							variant: 'h6',
							color: 'text.primary',
						}}
						secondaryTypographyProps={{ component: 'div' }} // Avoid 'div cannot be descendant of p' error
						secondary={
							<Stack>
								{/* <Stack */}
								{/* 	direction='row' */}
								{/* 	gap={1}> */}
								{latestMessage && (
									<Stack
										direction='row'
										flex={1}
										gap={1}
										overflow='hidden'>
										<Typography
											textOverflow={'ellipsis'}
											variant='body2'
											color='text.primary'>
											{latestMessage.sender.id === me?.id
												? 'Me'
												: latestMessage.sender.name.split(' ')[0]}
										</Typography>
										<Typography
											variant='body2'
											noWrap>
											{'- ' + latestMessage.content}
										</Typography>
									</Stack>
								)}
								{/* {dayjs(latestMessage?.sent || wanna.created).fromNow()} */}
								{/* </Stack> */}
								<Stack
									direction='row'
									gap={1}
									pt={0.5}>
									<EventIcon />
									{startTime.isToday()
										? startTime.format('h:mm A')
										: startTime.format('ddd, MMM D, h:mm a')}
								</Stack>
							</Stack>
						}
					/>
				</ListItemButton>
			</ListItem>
			<LazyUserGraph
				wannaId={wanna.id}
				users={(
					participantships
						?.map((p) => p.user)
						?.filter((user) => user.id !== me?.id) ?? []
				).concat(meWithConnections ? [meWithConnections] : [])}
				myChip={
					me && (
						<ImDown
							key={me?.id}
							wannaId={wanna.id}
						/>
					)
				}
				statuses={participantships?.reduce(
					(acc, p) => ({ ...acc, [p.user.id]: p.status }),
					{}
				)}
				useImDown={true}
			/>
			<Divider />
		</Stack>
	)
}
