import { useEffect } from 'react'
import { useLocation } from 'react-router'

export const useNotificationAutoClear = () => {
	const location = useLocation()
	useEffect(() => {
		navigator.serviceWorker?.getRegistration().then((registration) => {
			if (!registration?.getNotifications) {
				// Unsupported on iOS Safari if not saved to home screen
				return
			}
			registration
				?.getNotifications({ tag: location.pathname })
				?.then((notifications) => {
					notifications.forEach((notification) => {
						notification.close()
					})
				})
		})
	}, [location.pathname])
}
