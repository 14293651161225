import { Checkbox, CircularProgress, FormControlLabel } from '@mui/material'

import { useState } from 'react'
import { usePushNotifications } from '../../hooks/usePushNotifications'
import { InstallInstructions } from './InstallInstructions'
import { useIsDemo } from '../../hooks/useIsDemo'

export const TogglePushNotifications = () => {
	const [isPushEnabled, setPushEnabled, isPushLoading] = usePushNotifications()
	const isDemo = useIsDemo()
	const [showInstructions, setShowInstructions] = useState(false)

	return (
		<>
			<FormControlLabel
				label='receive push notifications'
				disabled={isDemo || isPushLoading}
				sx={{ color: 'text.primary' }}
				control={
					isPushLoading ? (
						<CircularProgress
							size={20}
							sx={{ m: '11px' }} // Align with checkbox location
						/>
					) : (
						<Checkbox
							checked={isPushEnabled}
							onClick={() => {
								if (setPushEnabled) {
									setPushEnabled(!isPushEnabled)
								} else {
									setShowInstructions(true)
								}
							}}
						/>
					)
				}
			/>
			<InstallInstructions
				isOpen={showInstructions}
				onClose={() => setShowInstructions(false)}
			/>
		</>
	)
}
