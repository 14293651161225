import { makeReference, useMutation } from '@apollo/client'
import SEND_MESSAGE from '../gql/sendMessage.gql'
import { useMe } from './useMe'
import { OPTIMISTIC_ID } from '../utils/constants'

export const useSend = (wannaId) => {
	const me = useMe()

	const [sendMessage] = useMutation(SEND_MESSAGE, {
		variables: {
			wannaId,
		},
		update: (cache, { data }) => {
			cache.modify({
				id: `Wanna:${wannaId}`,
				fields: {
					messages: (existingMessages = []) => {
						const newMessageRef = makeReference(
							cache.identify(data.sendMessage)
						)
						return [newMessageRef, ...existingMessages]
					},
				},
			})
		},
	})

	return (content) => {
		sendMessage({
			variables: {
				content,
			},
			optimisticResponse: {
				__typename: 'Mutation',
				sendMessage: {
					__typename: 'Message',
					id: OPTIMISTIC_ID,
					sent: Date.now(),
					content,
					likes: 0,
					sender: {
						__typename: 'User',
						id: me.id,
						name: me.name,
						pic: me.pic,
					},
					seenBy: [],
				},
			},
		})
	}
}
