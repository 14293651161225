import { useMutation } from '@apollo/client'

import { makeReference } from '@apollo/client'
import ADD_FRIEND from '../gql/friendUser.gql'

export function useFriendUser() {
	const [friendUser, friendUserMutation] = useMutation(ADD_FRIEND)

	return {
		addFriend: (id) =>
			friendUser({
				variables: {
					id,
					isFriend: true,
				},
				optimisticResponse: {
					__typename: 'Mutation',
					friendUser: {
						id: id,
						__typename: 'User',
					},
				},
				update: (cache, { data: { friendUser } }) => {
					// TODO: test these
					cache.modify({
						id: cache.identify(friendUser),
						fields: {
							connectedBy: (existingConnections = []) =>
								existingConnections.concat([{ __typename: 'Friendship' }]),
						},
					})
					cache.modify({
						id: cache.identify(makeReference('me')),
						fields: {
							connections: (existingConnections = []) =>
								existingConnections.concat([
									{ __ref: cache.identify(friendUser) },
								]),
						},
					})
				},
			}),
		removeFriend: (id) =>
			friendUser({
				variables: {
					id,
					isFriend: false,
				},
				optimisticResponse: {
					__typename: 'Mutation',
					friendUser: {
						id: id,
						__typename: 'User',
					},
				},
				update: (cache, { data: { friendUser } }) => {
					cache.modify({
						id: cache.identify(friendUser),
						fields: {
							connectedBy: (existingConnections = []) =>
								existingConnections.filter(
									(connection) => connection.__typename !== 'Friendship'
								),
						},
					})
					cache.modify({
						id: cache.identify(makeReference('me')),
						fields: {
							connections: (existingConnections = []) => {
								const friendUserRef = cache.identify(friendUser)
								return existingConnections.filter(
									(connection) => connection.__ref !== friendUserRef
								)
							},
						},
					})
				},
			}),
		loading: friendUserMutation.loading,
	}
}
