import { useQuery } from '@apollo/client'
import GET_CONNECTED_WANNAS from '../gql/getConnectedWannas.gql'
import ON_WANNA_CREATED from '../gql/onWannaCreated.gql'
import { useMessagesSubscription } from './useMessagesSubscription'
import { useEffect } from 'react'

export const useConnectedWannas = () => {
	const { data, subscribeToMore } = useQuery(GET_CONNECTED_WANNAS, {})
	const me = data?.me
	const connections = me?.connections

	useEffect(() => {
		return subscribeToMore({
			document: ON_WANNA_CREATED,
			updateQuery: (prev, { subscriptionData }) => {
				if (!subscriptionData.data) return prev
				const wanna = subscriptionData.data.wannas

				return {
					...prev,
					me: {
						...prev.me,
						wannas:
							wanna.creator.id === prev.me.id
								? [wanna, ...prev.me.wannas]
								: prev.me.wannas,
						connections: prev.me.connections.map((connection) => {
							return connection.id === wanna.creator.id
								? {
										...connection,
										wannas: [wanna, ...connection.wannas],
								  }
								: connection
						}),
					},
				}
			},
		})
	}, [])

	const allWannas = []
		.concat(me?.wannas ?? [])
		.concat(connections?.map((connection) => connection.wannas).flat() ?? [])
		.filter((wanna) => wanna !== undefined && wanna !== null)
		.filter(
			(wanna, index, self) => self.map((w) => w.id).indexOf(wanna.id) === index
		)

	useMessagesSubscription(allWannas.map((wanna) => wanna.id))

	return allWannas
}
