import { useMutation } from '@apollo/client'
import I_WANNA from '../gql/iWanna.gql'
import { useMe } from './useMe'

export const useIWanna = () => {
	const me = useMe()
	const [iWanna] = useMutation(I_WANNA, {
		update: (cache, { data: { iWanna } }) => {
			cache.modify({
				id: cache.identify(me),
				fields: {
					wannas: (existingWannas = []) => {
						const newWannaId = cache.identify(iWanna)
						return [{ __ref: newWannaId }, ...existingWannas]
					},
				},
			})
		},
	})

	return (activity, shareable, startTime, visibleUserIds) => {
		iWanna({
			variables: {
				activity,
				shareable,
				startTime,
				visibleUserIds,
			},
		})
	}
}
