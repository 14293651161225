import { useMutation, useQuery } from '@apollo/client'
import MoreVert from '@mui/icons-material/MoreVert'
import PersonAdd from '@mui/icons-material/PersonAdd'
import {
	AppBar,
	Avatar,
	Checkbox,
	Chip,
	CircularProgress,
	Dialog,
	DialogTitle,
	IconButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Stack,
	Toolbar,
	Typography,
} from '@mui/material'
import { useState } from 'react'
import { useParams } from 'react-router'
import { GoBackButton } from '../../components/GoBackButton'
import { ShareButton } from '../../components/ShareButton'
import { ToolbarTitle } from '../../components/ToolbarTitle'
import { LazyUserGraph } from '../../components/LazyUserGraph'
import { UserSelector } from '../../components/UserSelector'
import GET_CIRCLE from '../../gql/getCircle.gql'
import SET_PRIVACY from '../../gql/setCirclePrivacy.gql'
import { useAddMember } from '../../hooks/useAddMember'
import { useLeaveCircle } from '../../hooks/useLeaveCircle'
import { useMe } from '../../hooks/useMe'
import { LoginButton } from '../../components/LoginButton'
import { useMeWithConnections } from '../../hooks/useMeWithConnections'

export const Circle = () => {
	const circleId = useParams().id
	const me = useMe()
	const meWithConnections = useMeWithConnections()
	const [searchOpen, setSearchOpen] = useState(false)
	const [anchorEl, setAnchorEl] = useState(null)
	const leaveCircle = useLeaveCircle(circleId)
	const { data: { circle } = {} } = useQuery(GET_CIRCLE, {
		variables: {
			id: circleId,
		},
	})
	const { addMember } = useAddMember(circleId, circle?.members)
	const [setPrivacy, setPrivacyMutation] = useMutation(SET_PRIVACY, {
		variables: {
			id: circleId,
		},
	})
	const amIMember = circle?.members?.some((member) => member.id === me?.id)

	return (
		<Stack
			height='100%'
			minHeight={0}>
			<AppBar position='static'>
				<Toolbar sx={{ color: 'white' }}>
					<GoBackButton />
					<ToolbarTitle>{circle?.name}</ToolbarTitle>
					<ShareButton
						text={`Come join ${circle?.name} on Wanna!`}
						link={window.location.href}
					/>
					{amIMember && (
						<>
							<IconButton
								onClick={() => setSearchOpen(true)}
								color='inherit'>
								<PersonAdd />
							</IconButton>
							<IconButton
								onClick={(e) => setAnchorEl(e.currentTarget)}
								color='inherit'>
								<MoreVert />
							</IconButton>
						</>
					)}
					<Menu
						sx={{ mt: '40px' }}
						anchorEl={anchorEl}
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						keepMounted
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						open={anchorEl !== null}
						onClose={() => setAnchorEl(null)}>
						<MenuItem
							onClick={() =>
								setPrivacy({ variables: { isPrivate: !circle?.private } })
							}>
							<ListItemIcon>
								{setPrivacyMutation.loading ? (
									<CircularProgress size={20} />
								) : (
									<Checkbox
										checked={circle?.private === true}
										sx={{ p: 0 }}
									/>
								)}
							</ListItemIcon>
							<ListItemText>Private</ListItemText>
						</MenuItem>
					</Menu>
				</Toolbar>
			</AppBar>
			{!me && (
				<Stack
					direction={'row'}
					alignItems={'center'}
					p={1}
					gap={1}>
					<LoginButton />
					<Typography>to join the fun!</Typography>
				</Stack>
			)}
			<LazyUserGraph
				height='100%'
				users={(
					circle?.members?.filter((user) => user.id !== me?.id) ?? []
				).concat(meWithConnections ? [meWithConnections] : [])}
				myChip={
					me && (
						<Chip
							onClick={(e) => {
								e.stopPropagation()
								if (!amIMember) addMember(me.id)
								else leaveCircle()
							}}
							variant={amIMember ? 'contained' : 'outlined'}
							label={"I'm in!"}
							color='secondary'
							avatar={
								<Avatar
									alt={me?.name}
									src={me?.pic}
								/>
							}
						/>
					)
				}
			/>
			<Dialog
				open={searchOpen}
				onClose={() => setSearchOpen(false)}>
				<DialogTitle sx={{ pb: 0 }}>add members</DialogTitle>
				<UserSelector
					initialChip={'search'}
					selectedUserIds={circle?.members?.map((user) => user.id)}
					onSelected={(userIds) => {
						userIds.forEach((userId) => {
							addMember(userId)
						})
					}}
				/>
			</Dialog>
		</Stack>
	)
}
