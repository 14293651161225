import { blue, green, orange, purple, red, yellow } from '@mui/material/colors'

export function getTimeAgo(sent) {
	const now = new Date()
	const diff = Math.abs(now - new Date(sent))
	const minutes = Math.floor(diff / 60000)
	if (minutes < 60) return minutes + 'm'
	const hours = Math.floor(minutes / 60)
	if (hours < 24) return hours + 'h'
	const days = Math.floor(hours / 24)
	return days + 'd'
}

// https://stackoverflow.com/questions/16801687/javascript-random-ordering-with-seed
export function shuffle(array, seed) {
	var m = array.length,
		t,
		i

	// While there remain elements to shuffle…
	while (m) {
		// Pick a remaining element…
		i = Math.floor(random(seed) * m--)

		// And swap it with the current element.
		t = array[m]
		array[m] = array[i]
		array[i] = t
		++seed
	}

	return array
}

function random(seed) {
	var x = Math.sin(seed++) * 10000
	return x - Math.floor(x)
}

function cyrb53(str, seed = 0) {
	let h1 = 0xdeadbeef ^ seed,
		h2 = 0x41c6ce57 ^ seed
	for (let i = 0, ch; i < str.length; i++) {
		ch = str.charCodeAt(i)
		h1 = Math.imul(h1 ^ ch, 2654435761)
		h2 = Math.imul(h2 ^ ch, 1597334677)
	}
	h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507)
	h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909)
	h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507)
	h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909)

	return 4294967296 * (2097151 & h2) + (h1 >>> 0)
}

export function share(text, link) {
	if (navigator.canShare) {
		// Invoke device's native sharing UI
		// ! Android FF does not support `text`. Pass as `title` in that case?
		navigator.share({ title: text, url: link })
	} else {
		// Browser, so we just copy to clipboard
		window.navigator.clipboard.writeText(text + ' ' + link)
	}
	return navigator.canShare
}

export function cmpWannas(wannaA, wannaB) {
	return (
		(wannaB.messages[0]?.sent ?? wannaB.created) -
		(wannaA.messages[0]?.sent ?? wannaA.created)
	)
}

const colors = [
	orange[500],
	green[500],
	red[500],
	blue[500],
	purple[500],
	yellow[800],
]
export function getColor(wannaId, userId, meId) {
	if (userId === meId) return '#F0F0F0'

	return colors[Math.abs(cyrb53(userId + wannaId)) % colors.length]
}
