import { makeReference, useMutation, useQuery } from '@apollo/client'
import IM_DOWN from '../gql/imDown.gql'
import IM_DOWN_DATA from '../gql/imDownData.gql'
import IM_NOT_DOWN from '../gql/imNotDown.gql'
import { useMe } from './useMe'
import { OPTIMISTIC_ID } from '../utils/constants'

export const useImDown = (wannaId) => {
	const me = useMe()
	const imDownData = useQuery(IM_DOWN_DATA, {
		variables: {
			wannaId,
		},
	})
	const participantships = imDownData.data?.wanna.participantships
	const myParticipantship = participantships?.find((p) => p.user.id === me?.id)
	const amIDown = myParticipantship != null

	const [imDown] = useMutation(IM_DOWN, {
		optimisticResponse: {
			__typename: 'Mutation',
			imDown: {
				__typename: 'Participantship',
				id: OPTIMISTIC_ID,
				user: {
					...me,
					// NOTE: we don't include connections here, which the graph queries for.
					// But I think it'll be fine because we query for me and me.connections and
					// pass those in to the graph separately; we don't get it from participantships.connections.
					connections: [],
				},
				created: Date.now(),
				status: null,
			},
		},
		update: (cache, { data: { imDown } }) => {
			cache.modify({
				id: `Wanna:${wannaId}`,
				fields: {
					participantships: (existingParticipantships = []) => {
						const newParticipantshipRef = makeReference(cache.identify(imDown))
						if (
							existingParticipantships.some(
								(p) => p.__ref === newParticipantshipRef.__ref
							)
						) {
							// May have already updated from subscription
							return existingParticipantships
						}
						return [newParticipantshipRef, ...existingParticipantships]
					},
				},
			})
			cache.modify({
				id: cache.identify(me),
				fields: {
					wannas: (existingWannas = []) => {
						const wannaRef = makeReference(`Wanna:${wannaId}`)
						return [wannaRef, ...existingWannas]
					},
				},
			})
		},
	})

	const [imNotDown] = useMutation(IM_NOT_DOWN, {
		variables: {
			wannaId,
		},
		optimisticResponse: {
			__typename: 'Mutation',
			imNotDown: {
				id: myParticipantship?.id,
				__typename: 'Participantship',
			},
		},
		update: (cache, { data: { imNotDown } }) => {
			cache.modify({
				id: cache.identify(me),
				fields: {
					wannas: (existingWannas = []) => {
						// Server will always return our own wannas, so don't remove it
						if (imDownData.data?.wanna.creator.id === me.id) return existingWannas

						const leftWannaId = cache.identify({
							__typename: 'Wanna',
							id: wannaId,
						})
						return existingWannas.filter((w) => w.__ref !== leftWannaId)
					},
				},
			})
			cache.modify({
				id: `Wanna:${wannaId}`,
				fields: {
					participantships: (existingParticipantships = []) => {
						if (!imNotDown) return existingParticipantships
						const deletedParticipantshipRef = cache.identify(imNotDown)
						return existingParticipantships.filter(
							(p) => p.__ref !== deletedParticipantshipRef
						)
					},
				},
			})
		},
	})

	return {
		imDown: (shareWithUserIds) =>
			imDown({
				variables: {
					wannaId,
					shareWithUserIds,
				},
			}),
		imNotDown,
		amIDown,
		shareable: imDownData.data?.wanna.shareable,
	}
}
