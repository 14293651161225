import { Avatar, Chip, Box, Collapse, Stack } from '@mui/material'
import { getColor } from '../utils/utils'
import { forwardRef } from 'react'

export const ParticipantChip = forwardRef(({ wannaId, user, status }) => {
	const color = getColor(wannaId, user.id)
	const present = status === 'present' || status === 'typing'
	return (
		<Box
			color={color}
			position='relative'>
			<Chip
				// TODO: ehh idk if this is clear rn
				size={present ? 'medium' : 'small'}
				avatar={
					<Avatar
						alt={user.name}
						src={user.pic}
					/>
				}
				sx={{
					// can't use `Chip.color` because it must be from palette
					background: color,
					color: 'white',
					//border: present ? '2px solid black' : undefined
				}}
				label={user.name.split(' ')[0]}
			/>
			<TypingIndicator isOpen={status === 'typing'} />
		</Box>
	)
})
ParticipantChip.displayName = 'ParticipantChip'

const TypingIndicator = ({ isOpen }) => {
	return (
		<Collapse
			in={isOpen}
			sx={{ position: 'absolute', zIndex: 10, left: 30, top: -11 }}
			orientation='horizontal'>
			<Stack direction='row' className='typing-indicator'>
				<span />
				<span />
				<span />
			</Stack>
		</Collapse>
	)
}

// For FlipMove
ParticipantChip.prototype = {}
