import { CssBaseline, useMediaQuery } from '@mui/material'
import './assets/css/App.css'
import { ThemeProvider } from '@mui/material/styles'
import { createTheme, responsiveFontSizes } from '@mui/material/styles/index.js'
import React, { useCallback, useMemo, useState } from 'react'
import { useCookies } from 'react-cookie'

export const ColorModeContext = React.createContext({ mode: 'light', toggle: () => {} })

export const AppTheme = ({ children }) => {
	const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
	const [cookies, setCookies] = useCookies(['mode'])
	const [mode, setMode] = useState(cookies.mode ?? (prefersDarkMode ? 'dark' : 'light'))
	const toggleColorMode = useCallback(() => {
		setMode((prevMode) => {
			const newMode = prevMode === 'light' ? 'dark' : 'light'
			setCookies('mode', newMode, { path: '/', expires: false })
			return newMode
		})
	}, [])

	const theme = useMemo(() => {
		let theme = createTheme({
			palette: {
				mode: mode,
				primary: { main: '#2979FF' },
				secondary: { main: '#EC407A' },
			},
			typography: {
				fontFamily: ['Qarmic sans'],
				// idk why but have to specify this one separately
				body: {
					fontFamily: ['Qarmic sans'],
				},
			},
			components: {
				MuiAppBar: {
					styleOverrides: {
						colorPrimary: {
							backgroundColor: '#2979FF',
						},
						root: ({ theme }) =>
							theme.unstable_sx({
								backgroundImage: ''
							}),
					},
				},
				MuiButton: {
					styleOverrides: {
						root: {
							textTransform: 'none',
						},
					},
				},
			},
		})
		theme = responsiveFontSizes(theme)
		return theme
	}, [mode])

	return (
		<ColorModeContext.Provider value={{ mode, toggle: toggleColorMode }}>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				{children}
			</ThemeProvider>
		</ColorModeContext.Provider>
	)
}
