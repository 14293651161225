import { useMutation } from '@apollo/client'
import CREATE_CIRCLE from '../gql/createCircle.gql'
import { makeReference } from '@apollo/client'
import { useMe } from './useMe'

export const useCreateCircle = () => {
	const me = useMe()
	const [createCircle] = useMutation(CREATE_CIRCLE, {
		update(cache, { data: { createCircle } }) {
			cache.modify({
				id: cache.identify(makeReference('ROOT_QUERY')),
				fields: {
					circles: (existingCircleIds = []) => {
						const newCircleId = cache.identify(createCircle)
						return [{ __ref: newCircleId }, ...existingCircleIds]
					},
				},
			})
		},
	})

	return (name, onCompleted) =>
		createCircle({
			variables: {
				name,
				isPrivate: false, // not used for now
			},
			optimisticResponse: {
				__typename: 'Mutation',
				createCircle: {
					__typename: 'Circle',
					id: 'temp-id',
					name,
					private: false,
					members: [me],
				},
			},
			onCompleted,
		})
}
