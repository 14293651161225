import {
	Avatar,
	Chip,
	Dialog,
	DialogContentText,
	Button,
	DialogActions,
} from '@mui/material'
import { forwardRef, useEffect, useState } from 'react'
import { useMe } from '../hooks/useMe'
import { useImDown } from '../hooks/useImDown'
import { UserSelector } from './UserSelector'
import { useMyConnections } from '../hooks/useMyConnections.js'

export const ImDown = forwardRef(({ wannaId }, ref) => {
	const me = useMe()
	const [isVisibilityOpen, setIsVisibilityOpen] = useState(false)
	const [visibleToIds, setVisibleToIds] = useState([])
	const myConnections = useMyConnections()
	useEffect(() => {
		setVisibleToIds(myConnections?.map((user) => user.id) ?? [])
	}, [myConnections])

	const { imDown, imNotDown, amIDown, shareable } =
		useImDown(wannaId)

	return (
		<>
			<Chip
				ref={ref}
				onClick={(e) => {
					e.stopPropagation()
					if (amIDown) imNotDown()
					else if (shareable) setIsVisibilityOpen(true)
					else imDown()
				}}
				variant={amIDown ? 'contained' : 'outlined'}
				label={"I'm down!"}
				color='secondary'
				avatar={
					<Avatar
						alt={me?.name}
						src={me?.pic}
					/>
				}
			/>
			{isVisibilityOpen && (
				<Dialog
					open={isVisibilityOpen}
					onClose={() => setIsVisibilityOpen(false)}>
					<DialogContentText
						p={2}
						pb={0}>
						this activity allows friends-of-friends!
						<br />
						share it with...
					</DialogContentText>
					<UserSelector
						selectedUserIds={visibleToIds}
						onSelected={(userIds) => setVisibleToIds((prev) => prev.concat(userIds))}
						onDeselected={(userIds) => setVisibleToIds((prev) => prev.filter((id) => !userIds.includes(id)))}
					/>
					<DialogActions>
						<Button onClick={() => setIsVisibilityOpen(false)}>cancel</Button>
						<Button
							onClick={() => {
								imDown(visibleToIds)
								setIsVisibilityOpen(false)
							}}>
							confirm
						</Button>
					</DialogActions>
				</Dialog>
			)}
		</>
	)
})
ImDown.displayName = 'ImDown'
