import { GoogleOAuthProvider } from '@react-oauth/google'
import { WannaRouter } from './routes/WannaRouter'
import { AppTheme } from './AppTheme.jsx'
import { BrowserRouter } from 'react-router-dom'
import { initSentry } from './lib/sentry'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import './lib/dayjs'

initSentry()

export const App = () => {
	return (
		<AppTheme>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<GoogleOAuthProvider
					clientId={import.meta.env.VITE_GOOGLE_OAUTH_CLIENT_KEY}>
					<BrowserRouter>
						<WannaRouter />
					</BrowserRouter>
				</GoogleOAuthProvider>
			</LocalizationProvider>
		</AppTheme>
	)
}
