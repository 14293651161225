import VisibilityIcon from '@mui/icons-material/Visibility'
import {
	Button,
	Checkbox,
	Dialog,
	DialogTitle,
	FormControl,
	FormControlLabel,
	FormGroup,
	Paper,
	Stack,
	Typography,
} from '@mui/material'
import EventIcon from '@mui/icons-material/Event'
import { MobileDateTimePicker } from '@mui/x-date-pickers'
import { useCallback, useRef, useState, useEffect } from 'react'
import Typed from 'typed.js'
import { useIWanna } from '../../hooks/useIWanna.js'
import { shuffle } from '../../utils/utils.js'
import dayjs from 'dayjs'
import { UserSelector } from '../../components/UserSelector.jsx'
import { useMyConnections } from '../../hooks/useMyConnections.js'

export const IWanna = ({ isOpen, onClose }) => {
	// Settings
	const [activity, setActivity] = useState('')
	const [unchangedDate] = useState(dayjs())
	const [date, setDate] = useState(unchangedDate)
	const [isVisibilityOpen, setIsVisibilityOpen] = useState(false)
	const [visibleToIds, setVisibleToIds] = useState([])
	const myConnections = useMyConnections()
	useEffect(() => {
		setVisibleToIds(myConnections?.map((user) => user.id) ?? [])
	}, [myConnections])
	const [shareable, setShareable] = useState(true)

	const iWanna = useIWanna()

	const typedRef = useRef()
	const inputRef = useCallback((node) => {
		typedRef.current?.destroy()
		if (!node) return
		typedRef.current = new Typed(node, {
			strings: activites,
			typeSpeed: 70,
			backSpeed: 50,
			backDelay: 500,
			attr: 'placeholder',
			loop: true,
		})
	}, [])

	return (
		<>
			<Dialog
				// To nest inside demo device. Seems functionally the same outside of demo.
				// TODO: backdrop covers device frame, not just screen
				disablePortal
				sx={{
					'& .MuiDialog-paper': {
						backgroundColor: 'primary.main',
						color: 'white',
						borderRadius: '16px',
					},
				}}
				open={isOpen}
				onClose={() => {
					setActivity('')
					onClose()
				}}>
				<Stack
					maxWidth={'300px'}
					p={2}
					gap={1}
					alignItems={'center'}>
					<Paper
						elevation={2}
						style={{
							background: 'white',
							borderRadius: '8px',
							outline: 'none',
							width: '100%',
						}}>
						<input
							ref={inputRef}
							style={{
								width: '100%',
								background: 'white',
								borderRadius: '8px',
								fontSize: '20px',
								padding: '8px',
								textAlign: 'center',
								border: 'none',
								fontFamily: 'inherit',
								outline: 'none',
							}}
							placeholder=''
							value={activity}
							onChange={(e) => setActivity(e.target.value)}
						/>
					</Paper>
					<ButtonField
						startIcon={<VisibilityIcon />}
						onClick={() => setIsVisibilityOpen(true)}>
						{`share with ${visibleToIds.length} ${visibleToIds.length === 1 ? 'person' : 'people'}`}
					</ButtonField>
					<MobileDateTimePicker
						slots={{ field: ButtonField }}
						slotProps={{ value: date }}
						minDate={unchangedDate}
						minTime={unchangedDate}
						maxDate={dayjs().add(1, 'month')}
						disableIgnoringDatePartForTimeValidation={true}
						value={date}
						onChange={(newDate) => setDate(newDate)}
					/>
					<Typography
						variant='caption'
						mt={-0.5}>
						visible until 3 hours of inactivity
					</Typography>
					<FormControl sx={{ alignSelf: 'flex-start', px: 1, pb: 0.5, }}>
						<FormGroup>
							<FormControlLabel
								label={
									<Stack>
										<Typography variant='body'>shareable</Typography>
										<Typography variant='caption'>
											when a friend joins, let their friends join too
										</Typography>
									</Stack>
								}
								control={
									<Checkbox
										sx={{ color: 'white' }}
										color='secondary'
										checked={shareable}
										onClick={() => setShareable((prev) => !prev)}
									/>
								}
							/>
						</FormGroup>
					</FormControl>
					<Button
						sx={{ alignSelf: 'stretch', borderRadius: '8px', fontSize: '16px' }}
						variant='contained'
						color='secondary'
						onClick={() => {
							iWanna(activity, shareable, date.unix(), visibleToIds)
							onClose()
							setActivity('')
						}}
						disabled={!activity || activity.length > 32 || visibleToIds <= 0}>
						create
					</Button>
					{/* <Typography */}
					{/* 	variant='caption' */}
					{/* 	textAlign={'center'}> */}
					{/* 	remains visible until 12 hours of inactivity */}
					{/* </Typography> */}
				</Stack>
			</Dialog>
			<Dialog
				open={isVisibilityOpen}
				onClose={() => setIsVisibilityOpen(false)}>
				<DialogTitle>share with...</DialogTitle>
				<UserSelector
					selectedUserIds={visibleToIds}
					onSelected={(userIds) =>
						setVisibleToIds((prev) => prev.concat(userIds))
					}
					onDeselected={(userIds) =>
						setVisibleToIds((prev) =>
							prev.filter((id) => !userIds.includes(id))
						)
					}
				/>
			</Dialog>
		</>
	)
}

const ButtonField = (props) => {
	return (
		<Button
			sx={{ alignSelf: 'stretch', borderRadius: '8px' }}
			variant='outlined'
			size='large'
			onClick={props.onClick}
			color='white'
			startIcon={props.startIcon ?? <EventIcon />}>
			{props.children ??
				(props.value.isToday() ? 'today' : props.value.format('ddd, MMM D')) +
					' at ' +
					props.value.format('h:mm a')}
		</Button>
	)
}

const activites = shuffle(
	[
		'get dinner',
		'watch a movie',
		'hit the gym',
		'walk and talk',
		'play board games',
		'go for a ride',
		'read at the park',
	],
	Date.now()
)
