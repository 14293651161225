import dayjs from 'dayjs'

let startingMessageId = 100

export const users = {
	jeff: {
		__typename: 'User',
		id: 'jeff',
		name: 'Jeff Tsai',
		pic: '/demo/jeff.webp',
		recency: 0,
		wannas: [],
		connections: ['james', 'nick', 'sparkyy'].map((id) => ({
			__typename: 'User',
			id,
		})),
		connectedBy: [
			{
				__typename: 'Friendship',
			},
		],
	},
	james: {
		__typename: 'User',
		id: 'james',
		name: 'James Ewens',
		pic: '/demo/james.webp',
		recency: 0,
		wannas: [],
		connections: [],
		connectedBy: [
			{
				__typename: 'Friendship',
			},
		],
	},
	nick: {
		__typename: 'User',
		id: 'nick',
		name: 'Nick van Dyke',
		pic: '/demo/nick.webp',
		recency: 1,
		wannas: [],
		connections: ['sparkyy', 'james'].map((id) => ({ __typename: 'User', id })),
		connectedBy: [
			{
				__typename: 'Friendship',
			},
		],
	},
	sparky: {
		__typename: 'User',
		id: 'sparkyy',
		name: 'Sparky van Dyke',
		pic: '/demo/sparky.webp',
		recency: 0,
		wannas: [],
		connections: [],
		connectedBy: [
			{
				__typename: 'Friendship',
			},
		],
	},
	wout: {
		__typename: 'User',
		id: 'wout',
		name: 'Wout van Aert',
		pic: '/demo/wout.webp',
		recency: 0,
		wannas: [],
		connections: ['mathieu'].map((id) => ({ __typename: 'User', id })),
		connectedBy: [
			{
				__typename: 'Friendship',
			},
		],
	},
	mathieu: {
		__typename: 'User',
		id: 'mathieu',
		name: 'Mathieu van der Poel',
		pic: '/demo/mathieu.webp',
		recency: 0,
		wannas: [],
		connections: [],
		connectedBy: [
			{
				__typename: 'Friendship',
			},
		],
	},
	jonas: {
		__typename: 'User',
		id: 'jonas',
		name: 'Jonas Vingegaard',
		pic: '/demo/jonas.jpg',
		recency: 0,
		wannas: [],
		connections: [],
		connectedBy: [
			{
				__typename: 'Friendship',
			},
		],
	},
	tadej: {
		__typename: 'User',
		id: 'tadej',
		name: 'Tadej Pogačar',
		pic: '/demo/tadej.jpg',
		recency: 0,
		wannas: [],
		connections: ['jonas', 'wout'].map((id) => ({ __typename: 'User', id })),
		connectedBy: [
			{
				__typename: 'Friendship',
			},
		],
	},
}

export const me = {
	__typename: 'User',
	id: 'me',
	name: 'Lara Croft',
	pic: '/demo/lara.webp',
	recency: 0,
	connections: [
		// Need some guaranteed connections to align with the wannas we'll see
		users.nick,
		users.jeff,
		users.james,
		users.sparky,
		users.tadej,
	],
}

export const circles = [
	{
		__typename: 'Circle',
		id: '456',
		name: 'Cycling pals',
		private: false,
		members: [users.wout, users.mathieu, users.jonas, users.tadej, me],
	},
]

export const friends = [users.jeff, users.james, users.nick, users.sparky]

export const allUsers = [
	...friends,
	...circles.flatMap((circle) => circle.members),
]

export const initialWannas = [
	{
		id: '1239874kljnsdf',
		activity: 'pizza-palooza',
		created: hoursOldDate(1),
		startTime: dayjs().add(3, 'hour').unix(),
		shareable: true,
		creator: users.sparky,
		visibleTo: [me, users.jeff, users.sparky, users.james, users.nick],
		participantships: [
			{
				id: 1,
				user: me,
				created: minutesOldDate(31),
				status: null,
				addedBy: me,
			},
			{
				id: 2,
				user: friends[0],
				created: minutesOldDate(30),
				status: 'present',
				addedBy: me,
			},
			{
				id: 3,
				user: friends[1],
				created: minutesOldDate(30),
				status: null,
				addedBy: me,
			},
			{
				id: 4,
				user: friends[2],
				status: 'typing',
				addedBy: me,
				created: minutesOldDate(30),
			},
			{
				id: 5,
				user: friends[3],
				status: 'typing',
				addedBy: friends[0],
				created: Date.now(),
			},
		].map((p) => ({ ...p, __typename: 'Participantship' })),
		messages: [
			{
				id: 1,
				content: "Let's hit that Domino's deal",
				sent: minutesOldDate(10),
				sender: friends[0],
				seenBy: [friends[1]],
				likes: 5,
			},
			// {
			// 	id: 2,
			// 	content: 'Idk just not pineapple',
			// 	sent: minutesOldDate(9),
			// 	sender: me,
			// 	seenBy: [friends[0], friends[1]],
			// 	likes: 0,
			// },
			{
				id: 4,
				content: "Can they cut it into 6 slices? I can't finish 8",
				sent: minutesOldDate(3),
				sender: friends[1],
				seenBy: [friends[0]],
				likes: 0,
			},
			{
				id: 9,
				content: 'Is catnip a topping? 😺🍕',
				sent: minutesOldDate(1),
				sender: friends[3],
				seenBy: [],
				likes: 2,
			},
		]
			.reverse()
			.map((m) => ({ ...m, __typename: 'Message' })),
	},
	{
		id: 'cycling',
		activity: 'ride with friends',
		created: hoursOldDate(3),
		creator: users.wout,
		shareable: true,
		startTime: dayjs().add(5, 'hour').add(1, 'day').unix(),
		visibleTo: [users.wout, users.mathieu, users.jonas, users.tadej, me],
		participantships: [
			{
				id: 6,
				user: users.wout,
				created: Date.now(),
				status: 'present',
				addedBy: me,
			},
			{
				id: 7,
				user: users.mathieu,
				created: Date.now(),
				status: null,
				addedBy: me,
			},
			{
				id: 8,
				user: users.jonas,
				created: Date.now(),
				status: 'present',
				addedBy: me,
			},
			{
				id: 9,
				user: users.tadej,
				created: Date.now(),
				status: 'present',
				addedBy: me,
			},
		],
		messages: makeConvo(
			[
				{
					wout: 'Alright, first round’s on me. But only if we sprint to the bar. Winner takes all.',
				},
				{
					tadej:
						'I’d win, but I’m pacing myself for the long haul. Gotta keep my GC strategy tight, even at the bar.',
				},
				{
					mathieu:
						'Pacing? Please. I’ll bunny-hop over all of you, straight to the bar.',
				},
				{
					jonas:
						'You can hop all you want, Mathieu, but I’ll be there first—smooth and steady, like a perfectly timed time trial.',
				},
				{
					wout: 'I’ll just launch a surprise attack. You won’t see it coming.',
				},
				{
					mathieu:
						'Wout, buddy, we always see it coming. The only thing harder than catching you is your post-race interviews.',
				},
				{
					tadej:
						'As long as there’s no mountain in here, I think we’re all safe.',
				},
				{
					jonas: 'Just don’t let Wout drag us into a sprint finish… again.',
				},
				{
					wout: 'Oh, I’m attacking on the way to the jukebox. You guys can fight for second.',
				},
				{
					tadej: 'Second? That’s my thing!',
				},
				{
					mathieu: 'Can I at least choose the music this time?',
				},
				{
					jonas: 'As long as it’s not Tour de France on repeat.',
				},
				{
					tadej:
						'Fine, but only if we stay long enough for karaoke. I’ll nail We Are the Champions.',
				},
				{
					wout: 'Karaoke, huh? Maybe we should make this a stage race.',
				},
			],
			{ timeAdded: 17 }
		),
	},
].map((w) => ({ ...w, __typename: 'Wanna' }))

// TODO: one of these is from 'me'
export const notifications = initialWannas.map((wanna) => ({
	__typename: 'Notification',
	id: wanna.id,
	title: wanna.participantships[0].user.name,
	pic: wanna.participantships[0].user.pic,
	body: 'Wanna ' + wanna.activity + '?',
	path: '/app/wannas/' + wanna.id,
	timestamp: wanna.created,
	seen: false,
}))

function minutesOldDate(minutes) {
	return new Date(Date.now() - minutes * 60 * 1000)
}

function hoursOldDate(hours) {
	return new Date(Date.now() - hours * 60 * 60 * 1000)
}

function makeConvo(messages, { timeAdded }) {
	startingMessageId += 100
	return messages.map((m, i) => ({
		__typename: 'Message',
		id: startingMessageId + i,
		content: Object.values(m)[0],
		sent: minutesOldDate(i + timeAdded),
		sender: allUsers.find((u) => u.id === Object.keys(m)[0]),
		seenBy: [],
		likes: 0,
	}))
}
