import { useMutation, useQuery } from '@apollo/client'
import { AppBar, FormControl, List, Stack, Toolbar } from '@mui/material'
import { useEffect, useState } from 'react'
import { GoBackButton } from '../../components/GoBackButton'
import { ToolbarTitle } from '../../components/ToolbarTitle'
import GET_NOTIFICATIONS from '../../gql/getNotifications.gql'
import MARK_NOTIFICATIONS_AS_READ from '../../gql/markNotificationsAsRead.gql'
import { TogglePushNotifications } from '../settings/TogglePushNotifications'
import { NotificationItem } from './NotificationItem'

export const Notifications = () => {
	const [unread, setUnread] = useState(null)
	const { data: { notifications } = {} } = useQuery(GET_NOTIFICATIONS, {
		onCompleted: (data) => {
			// Store in state so we can continue showing them as unread even after they are marked as read
			if (!unread) setUnread(data.notifications.filter((n) => !n.seen))
		},
	})
	const [markRead] = useMutation(MARK_NOTIFICATIONS_AS_READ)
	useEffect(() => {
		if (unread?.length > 0) {
			markRead({
				variables: {
					ids: unread.map(({ id }) => id),
				},
			})
		}
	}, [unread])

	return (
		<Stack
			minHeight={0}
			height='100%'>
			<AppBar position='static'>
				<Toolbar>
					<GoBackButton />
					<ToolbarTitle>notifications</ToolbarTitle>
				</Toolbar>
			</AppBar>
			<FormControl sx={{ mx: 2, mt: 1 }}>
				<TogglePushNotifications />
			</FormControl>
			<List sx={{ overflow: 'auto' }}>
				{notifications
					?.concat()
					// Descending/most recent first
					?.sort((a, b) => b.timestamp - a.timestamp)
					?.map((notification) => (
						<NotificationItem
							key={notification.id}
							notification={notification}
							unread={unread?.some((n) => n.id === notification.id)}
						/>
					))}
			</List>
		</Stack>
	)
}
