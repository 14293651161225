import { styled, Switch } from '@mui/material'

export const LargeSwitch = styled(Switch)(({ theme }) => ({
	width: 92,
	height: 46,
	padding: 9,
	'& .MuiSwitch-switchBase': {
		marginTop: 3,
		padding: 0,
		transform: 'translateX(6px)',
		'&.Mui-checked': {
			transform: 'translateX(46px)',
		},
	},
	'& .MuiSwitch-thumb': {
		width: 40,
		height: 40,
	},
	'& .MuiSwitch-track': {
		borderRadius: 46 / 2,
	},
}))
