import GET_MY_CONNECTIONS from '../gql/getMyConnections.gql'
import { useQuery } from '@apollo/client'

// TODO: should maybe just combine into one useMeWithConnections hook or similar.
// Often used together. Kinda confusing to have separate.
export const useMyConnections = (context) => {
	const { data } = useQuery(GET_MY_CONNECTIONS, {
		variables: { context },
	})
	return data?.me?.connections
}
