import { makeReference, useSubscription } from '@apollo/client'
import ON_MESSAGES from '../gql/onMessages.gql'
import { useMe } from './useMe'

export const useMessagesSubscription = (wannaIds) => {
	const me = useMe()
	useSubscription(ON_MESSAGES, {
		variables: { wannaIds },
		shouldResubscribe: true,
		onSubscriptionData: ({ client, subscriptionData }) => {
			const msg = subscriptionData.data.messages
			const wannaId = msg.wannaId

			client.cache.modify({
				id: `Wanna:${wannaId}`,
				fields: {
					messages(existingMsgRefs = []) {
						const newMessageRef = makeReference(client.cache.identify(msg))
						const isMsgNew = !existingMsgRefs.some(
							(existingMsg) => existingMsg.__ref === newMessageRef.__ref
						)

						if (!isMsgNew || msg.sender.id === me?.id) {
							// Nothing else to do - Apollo has already updated the cached Message for us (with e.g. another `like`).
							// In the case where we're the sender, there seems to be a race condition (locally, at least)
							// with updating the cache in useSend. So don't do it here.
							return existingMsgRefs
						} else {
							return [newMessageRef, ...existingMsgRefs]
						}
					},
				},
			})
		},
	})
}
