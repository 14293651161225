import { useMe } from './useMe'
import { useMyConnections } from './useMyConnections'

export const useMeWithConnections = () => {
	const me = useMe()
	const myConnections = useMyConnections(me?.id)
	const meWithConnections =
		me && myConnections ? { ...me, connections: myConnections } : null
	return meWithConnections
}
