import { ApolloProvider } from '@apollo/client'
import * as Sentry from '@sentry/react'
import { Alert, LinearProgress, Snackbar } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import useAppBarHeight from '../hooks/useAppBarHeight.js'
import { useLocation, useNavigate } from 'react-router'
import {
	useApolloClient,
	useApolloNetworkStatus,
} from '../hooks/useApolloClient.js'
import { useIsDemo } from '../hooks/useIsDemo.js'
import { useDemoClient } from '../features/landing/useDemoClient.js'

export const NetworkContainer = ({ children }) => {
	const location = useLocation()
	const navigate = useNavigate()
	const appBarHeight = useAppBarHeight()
	const [error, setError] = useState(null)

	const isDemo = useIsDemo()

	const onError = useCallback(
		({ graphQLErrors, networkError }) => {
			if (graphQLErrors) {
				graphQLErrors.forEach((err) => {
					console.error(
						`[GraphQL error]: Message: ${err.message}, Location: ${err.locations}, Path: ${err.path}`,
					)
					Sentry.captureException(err)
					setError(err || new Error())
				})
			}

			if (networkError) {
				console.error(
					`[Network error (${networkError.statusCode})]: ${networkError}`,
				)
				Sentry.captureException(networkError)
				setError(networkError || new Error())
			}

			if (graphQLErrors?.some((err) => err.message === 'Not authenticated') || networkError?.statusCode === 401) {
				// Rely on server-side auth-protected routes, and just redirect when we hit one we can't access.
				// Single source of truth compared to also checking auth client-side.
				navigate('/')
			}
		},
		[setError],
	)
	// Kinda gross but the best way I can find for now - the awk part is
	// getting an onError into the Apollo client without instantiating it here.
	// TODO: oh, looks like useApolloNetworkStatus also provides errors!
	// So I can just reference those.
	const apolloClient = isDemo ? useDemoClient() : useApolloClient(onError)
	const { numPendingQueries, numPendingMutations } = useApolloNetworkStatus()

	useEffect(() => {
		let interval = null
		if (error) {
			interval = setInterval(() => {
				setError(null)
			}, 2000)
		} else {
			clearInterval(interval)
		}
		return () => clearInterval(interval)
	}, [error, setError])

	return (
		<ApolloProvider client={apolloClient}>
			{(numPendingQueries > 0 || numPendingMutations > 0) && (
				<LinearProgress
					sx={{
						zIndex: 11, // Otherwise will be underneath any children with `relative` position. Not relevant rn but playing it safe.
						position: 'absolute',
						top: location.pathname === '/' ? 0 : appBarHeight - 3,
						height: '3px',
						width: '100%',
						backgroundColor: 'transparent',
						'& .MuiLinearProgress-bar': {
							backgroundColor: 'rgb(173, 204, 255)',
						},
					}}
				/>
			)}
			<Snackbar
				open={error != null}
				onClose={() => setError(null)}
				sx={{ position: 'absolute' }}
				autoHideDuration={2000}>
				<Alert
					onClose={() => setError(null)}
					severity='error'
					sx={{ width: '100%' }}>
					{formatError(error)}
				</Alert>
			</Snackbar>
			{children}
		</ApolloProvider>
	)
}

const formatError = (error) => {
	if (!error) return null
	if (
		error.toString().includes('Failed to fetch') ||
		error.toString().includes('ERR_INTERNET_DISCONNECTED')
	)
		return 'Check your internet connection'
	return `Something went wrong. I've been notified :)`
}
