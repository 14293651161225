import { useMutation, useQuery } from '@apollo/client'
import GET_MY_STATUS from '../gql/getMyStatus.gql'
import UPDATE_MY_STATUS from '../gql/updateMyStatus.gql'
import { useMe } from './useMe'

export const useMyStatus = () => {
	const me = useMe()
	const { data } = useQuery(GET_MY_STATUS)
	const [updateMyStatus] = useMutation(UPDATE_MY_STATUS)

	return [
		data?.me.status,
		(status) =>
			{
				return updateMyStatus({
					variables: { status },
					optimisticResponse: {
						__typename: 'Mutation',
						updateMyStatus: {
							__typename: 'User',
							id: me?.id,
							status,
						},
					},
				})
			},
	]
}
