import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { NetworkContainer } from '../features/NetworkContainer.jsx'
import { Circle } from '../features/circles/Circle.jsx'
import { Circles } from '../features/circles/Circles'
import { Friends } from '../features/friends/Friends'
import { Landing } from '../features/landing/Landing'
import { Notifications } from '../features/notifications/Notifications.jsx'
import { Settings } from '../features/settings/Settings.jsx'
import { Wanna } from '../features/wanna/Wanna'
import { Wannas } from '../features/wannas/Wannas.jsx'
import { AutoPushRequester } from '../features/settings/AutoPushRequester.jsx'
import { Profile } from '../features/profile/Profile.jsx'

export const WannaRouter = () => (
	<Routes>
		<Route
			path='/*'
			element={
				<>
					<NetworkContainer>
						<Outlet />
					</NetworkContainer>
				</>
			}>
			<Route
				exact
				// It's weird but `/*` is the only way I can get React Router to not warn, even though just `''` works.
				path='/*'
				element={<Landing />}
			/>
			<Route
				path='app'
				element={
					<>
						<AutoPushRequester />
						<Outlet />
					</>
				}>
				<Route
					exact
					path=''
					element={
						<Navigate
							to='wannas'
							replace
						/>
					}
				/>
				<Route
					path='wannas'
					element={<Wannas />}
				/>
				<Route
					path='settings'
					element={<Settings />}
				/>
				<Route
					path='notifications'
					element={<Notifications />}
				/>
				<Route
					path='friends'
					element={<Friends />}
				/>
				<Route
					path='people/:id'
					element={<Profile />}
				/>
				<Route
					path='circles'
					element={<Circles />}
				/>
				<Route
					path='wannas/:id'
					element={<Wanna />}
				/>
				<Route
					path='circles/:id'
					element={<Circle />}
				/>
			</Route>
			<Route
				path='*'
				element={
					<Navigate
						to='/'
						replace
					/>
				}
			/>
		</Route>
	</Routes>
)
