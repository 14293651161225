import Logo from '../assets/img/horizontal-logo.svg'

// NOTE: imported svg file must have the text converted to a path
// (Inkscape: Path > Object to path) to display the font properly.
// Loading the font is a pain by comparison and the file only grew by 3kB (from 15kB)
export const HorizontalLogo = ({ style }) => {
	return (
		<img
			style={style}
			src={Logo}
		/>
	)
}
